import React, { useState } from 'react';
import logo from './logo.png';
import m14i from './m14i-v2.png';
import './App.css';
import { FileUploader } from 'react-drag-drop-files';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Button } from '@mui/material';
import { useMutation } from 'react-query';
import Lottie from "lottie-react";
import fileProcessing from './file_processing.json';

function CdiEmploiFranc() {
    const [file, setFile] = useState(null);
    const fileTypes = ["XLSX"];

    const handleChange = (file: React.SetStateAction<null>) => {
        setFile(file);
    };

    const uploadFile = async (file: any) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch('/api/upload', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'process_file'; // Change this to the desired file name
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('File upload failed:', error);
        }
    };

    const uploadFileQuery = useMutation(uploadFile, {
        onSuccess: () => {
            setFile(null);
        },
    })
    return (<div className='app_container'>
        <div className='header'>
            <img width="228" height="228" src={logo} alt="logo" />
            <a href="https://m14i.com">
                <img
                    src={m14i}
                    width="50"
                    alt="logo"
                    style={{ cursor: 'pointer' }}
                />
            </a>
        </div>
        {!uploadFileQuery.isLoading ?
        <FileUploader maxSize="1" handleChange={handleChange} name="file" types={fileTypes} >
            <div className='dd_container'>
                <FileUploadRoundedIcon fontSize='large' color='info' />
                {
                    file ?
                        <p className='dd-success'>{(file as any).name}</p>
                        :
                        <p className='dd_info'><span>Téléverser</span> ou Drag & Drop ton fichier <span>ici</span></p>
                }
            </div>
        </FileUploader> : 
        <Lottie animationData={fileProcessing} loop={true} />
        }
        <div className='footer'>
            <div className='footer_container'>
                <Button disabled={!file} variant="outlined" onClick={() => uploadFileQuery.mutate(file)}>Récupérer les zones prioritaires</Button>
            </div>
        </div>
    </div>)
}

export default CdiEmploiFranc;