import { QueryClient, QueryClientProvider } from "react-query";
import CdiEmploiFranc from "./CdiEmploiFranc";

function App() {
  
  const queryClient = new QueryClient();

  

  return (
    <QueryClientProvider client={queryClient}>
      <CdiEmploiFranc />
    </QueryClientProvider>
  );
}

export default App;
